@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.link-styled {
    color: #0e73f6;
}

.loading-logo {
    width: 180px;
    margin-bottom: 16px;
}

.MuiDataGrid-columnHeader:focus {
    outline: none !important;
}

.MuiDataGrid-cell:focus {
    outline: none !important;
}

.MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.light-border {
    border-radius: 10px;
    background-color: #e5e9eb;
}

.light-border img {
    border-radius: 10px;
}

/* style calendar week day label */
.MuiDayCalendar-weekDayLabel {
    background: none !important;
}
