img {
    width: 100%;
}

.logo-icon-regular {
    max-width: 200px;
    /* margin-right: 5px; */
}

.logo-icon {
    max-width: 18px;
    margin-right: 5px;
    filter: invert(1);
}

.logo-icon.logo-icon-scrollable {
    filter: none;
}

#section-1 {
    background-image: url(../../assets/svg/section1_background.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
}

#section-2 {
    /* background-image: url(../../assets/svg/section2_background.svg); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.section-3 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;
    gap: 3rem;
}

.home-features-1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    margin-bottom: 32px;
}

.home-features-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    margin-bottom: 32px;
}

.home-img {
    border-radius: 6px;
}

.home-features {
    border-radius: 6px;
    position: relative;
    bottom: -24px;
}

.home-features.small {
    bottom: -100px;
}

.home-workflow {
    border-radius: 6px 6px 0 0;
}

.left {
    left: -24px;
}

.right {
    right: -24px;
}

.footer-link {
    display: block;
    margin-bottom: 12px;
    text-decoration: none;
    color: black;
    font-weight: 400;
}

#section-3 {
    background-image: url(../../assets/svg/section2_background.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.span-buble {
    padding: 5px 10px;
    background: rgba(43, 97, 255, 0.1);
    border-radius: 41px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    letter-spacing: -0.01em;
    color: #2b61ff;
}

.h2-subtitle {
    font-weight: 700 !important;
    font-size: 2.5rem !important;
    line-height: 48px !important;
    /* identical to box height, or 120% */
    letter-spacing: -0.01em !important;
    color: #252c32;
}

.h2-subtitle.white {
    color: white;
}

.h4-subtitle {
    font-weight: 500 !important;
    font-size: 1.25rem !important;
    line-height: 156% !important;
    /* or 31px */
    letter-spacing: -0.02em !important;
    color: #fff;
}

.h5-placeholder {
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    /* font-size: 1.25rem !important; */
    /* identical to box height, or 25px */
    /* letter-spacing: -0.02em !important; */
}

.blue-highlights {
    color: #2b61ff;
}

.card-section {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    margin-bottom: 0.25rem;
}

.mobile-drawer {
    padding-right: 16px;
    margin: 64px 16px;
    display: flex;
    flex-direction: column;
    /* align-items: end; */
    border-right: 2px solid #2b61ff;
}

.mobile-drawer .guide-btn {
    color: "#252C32";
    background-color: "#FFF";
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    font-weight: "400";
    border-radius: "6px";
    /* padding: 4px 24px; */
    font-size: 0.875rem;
}
.mobile-drawer .guide-btn:hover {
    background-color: "#f5f5f5";
    color: "#252C32";
}

.blue-modal {
    padding: 60px 40px;
    background-color: #2b61ff;
    border-radius: 6px;
}

.modal-login-btn {
    margin-top: 18px;
}

@media (min-width: 600px) {
    /* Small */
    .home-features-2 {
        grid-template-columns: repeat(2, 1fr);
    }

    .card-section {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: 900px) {
    /* Medium */
    .home-features-1 {
        grid-template-columns: repeat(2, 1fr);
    }

    .section-3 {
        flex-wrap: nowrap;
        gap: 3rem;
    }

    .card-section {
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 0;
    }
    .blue-modal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 80px 40px;
    }
}

@media (min-width: 1200px) {
    /* Large */

    .home-features-2 {
        grid-template-columns: repeat(3, 1fr);
    }
    .card-section {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1536px) {
    /* Extra Large */
    .card-section {
        grid-template-columns: repeat(3, 1fr);
    }
}
