.setups-compare {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    gap: 1rem;
}

@media (min-width: 600px) {
}

/* Small */
@media (min-width: 900px) {
    .setups-compare {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Medium */
@media (min-width: 1200px) {
    .setups-compare {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* Large */
@media (min-width: 1536px) {
    .setups-compare {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 1920px) {
    /* For Projectors or Higher Resolution Screens (Full HD) */
    .setups-compare {
        grid-template-columns: repeat(4, 1fr);
    }
}
