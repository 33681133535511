.position-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 16px;
    margin-bottom: 8px;
    align-items: center;
}

.box-grid-sl-tp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
}

.box-grid-close {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    row-gap: 8px;
}

.preview-image {
    max-width: 800px;
    margin-bottom: 8px;
}

.image-placeholder {
    opacity: 0.7;
}

.execution-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 16px;
    margin-bottom: 8px;
    align-items: end;
}
