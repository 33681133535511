.rowSelected {
    background-color: rgb(215, 237, 255);
}

.table-title {
    padding: 20px 20px 0px;
}

.table-container {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    border-radius: 6px;
    border: 1px solid #e5e9eb;
    scrollbar-width: none;
    -ms-overflow-style: none; /* IE and Edge */
    /* box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); */
}

table {
    border-collapse: collapse;
    width: 100%;
    padding: 0px 5px;
    font-size: 14px;
}

tr {
    border-bottom: 1px solid rgb(224, 224, 224);
}

th {
    text-align: left;
    font-weight: 500;
    padding: 20px 20px 10px;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}

td {
    padding: 10px 20px;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}

.documentsBar {
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
}
.documentsBar::-webkit-scrollbar {
    display: none;
}
