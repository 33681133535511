.text-editor {
    font-family: "Inter";
    background-color: white;
}

.menu-editor {
    display: flex;
    border-bottom: 1px solid #dde2e4;
    background-color: #eff0f3;
}

.menu-editor-icon.is-active {
    background-color: #edeeef;
}

.info-tooltip {
    color: red;
}

.ProseMirror {
    padding: 12px 20px;
    min-height: 100px;
}
.ProseMirror:focus-visible {
    outline: none;
}

.ProseMirror > * + * {
    margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
    padding: 0 1rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
    line-height: 1.1;
}

.ProseMirror code {
    background-color: #cfcfcf;
    color: #616161;
}

.ProseMirror pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
}
.ProseMirror pre code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
}

.ProseMirror img {
    max-width: 100%;
    height: auto;
}

.ProseMirror blockquote {
    padding-left: 1rem;
    border-left: 2px solid #9c9c9c;
}

.ProseMirror hr {
    border: none;
    border-top: 2px solid #9c9c9c;
    margin: 2rem 0;
}

/* Editor Styles */
.text-editor h1 {
    font-size: 24px;
    margin: 5px 0;
}
.text-editor h2 {
    font-size: 22px;
    font-weight: 500;
    margin: 5px 0;
}
.text-editor h3 {
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 5px;
}
.text-editor h4 {
    font-size: 18px;
    text-decoration: underline;
    font-weight: 400;
    margin: 0 0 5px;
}
.text-editor h5 {
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 5px;
}
.text-editor h6 {
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 5px;
}

.text-editor p {
    font-size: 16px;
    margin: 0 0 2px;
}
