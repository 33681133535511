.calendar-tab {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1rem;
}

.calendar-grid {
    grid-column: span 7;
    order: 2;
}
.calendar-drawer {
    grid-column: span 7;
    order: 1;
}

.calendar-metrics {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
}

.calendar-controller {
    grid-column: span 2;
    order: 1;
}

.calendar-pnl {
    grid-column: span 1;
    order: 3;
}

.calendar-profit-factor {
    grid-column: span 1;
    order: 4;
}

.calendar-trades-number {
    grid-column: span 1;
    order: 6;
}

.calendar-result-breakdown {
    grid-column: span 2;
    order: 2;
}

.calendar-range-chart {
    grid-column: span 2;
    order: 5;
    display: flex;
    flex-direction: column;
    border: 1px solid #e5e9eb;
    border-radius: 5px;
    padding: 16px;
}

@media screen and (min-width: 1536px) {
    /* For 4K Displays (Ultra HD) */
    .calendar-grid {
        grid-column: span 4;
        order: 1;
    }

    .calendar-drawer {
        grid-column: span 3;
        order: 2;
    }

    .calendar-controller {
        grid-column: span 4;
    }

    .calendar-pnl {
        grid-column: span 2;
        order: 2;
    }

    .calendar-profit-factor {
        grid-column: span 2;
        order: 3;
    }

    .calendar-trades-number {
        grid-column: span 2;
        order: 5;
    }

    .calendar-result-breakdown {
        order: 6;
    }

    .calendar-range-chart {
        grid-column: span 4;
        order: 4;
    }
}

@media screen and (min-width: 1920px) {
    .calendar-trades-number {
        grid-column: span 2;
        order: 4;
    }

    .calendar-range-chart {
        grid-column: span 2;
        order: 5;
    }
}

.calendar-grid-item {
    border-bottom: 1px solid #e5e9eb;
    border-right: 1px solid #e5e9eb;
}

.calendar-grid-top {
    border-top: 1px solid #e5e9eb;
}

.calendar-grid-left {
    border-left: 1px solid #e5e9eb;
}

.calendar-day-text {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.not-current-month {
    background-color: #f0f1f2 !important;
}

.current-day {
    color: #0e73f6;
    background-color: #d7edff;
    border-radius: 5px;
}

.border-top-left {
    border-top-left-radius: 5px;
}

.border-top-right {
    border-top-right-radius: 5px;
}

.border-bottom-left {
    border-bottom-left-radius: 5px;
}

.border-bottom-right {
    border-bottom-right-radius: 5px;
}

.trade {
    border-radius: 5px;
}

.trade-loss {
    background-color: #fa4c58;
    color: black;
}

.trade-win {
    background-color: #95d5b2;
    color: black;
}

.trade-be {
    background-color: #ffda3d;
    color: black;
}

.trade-missing {
    background-color: #8ecae6;
    color: black;
}
