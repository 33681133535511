.support-icon {
    color: #0e73f6;
    border-radius: 50%;
    transition: 1s;
    background-color: #ffffff;
    border: 1px solid #e5e9eb;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.support-icon:hover {
    color: #d7edff;
    background-color: #0e73f6;
}

.support-icon-open {
    color: #d7edff;
    background-color: #0e73f6;
}

.transition-1 {
    transition: all 0.33s ease !important;
    -webkit-transition: all 0.33s ease !important;
    -moz-transition: all 0.33s ease !important;
}

.strip {
    position: relative;
    top: -45px;
    width: 100%;
    height: 308px;
    background-color: #0e73f6;
    transform: skewY(-12deg);
}

.support-window {
    position: fixed;
    bottom: 75px;
    right: 24px;
    width: 400px;
    height: 530px;
    max-width: calc(100% - 48px);
    max-height: calc(100% - 48px);
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid lightgray;
}

.email-form {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
}

.transition-2 {
    transition: all 5s ease !important;
    -webkit-transition: all 5s ease !important;
    -moz-transition: all 5s ease !important;
}

.support-text {
    color: white;
    font-size: 18px !important;
    position: relative;
    top: 38%;
}

.support-form {
    position: relative;
    width: 100%;
    top: 40%;
}

.email-input {
    width: 66%;
    font-size: 16px;
    text-align: center;
    outline: none;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #0e73f6;
}

.ql-container {
    border-left: none !important;
    border-right: none !important;
    border-color: #e5e9eb !important;
    border-radius: 5px 5px 0px 0px !important;
}

.ql-toolbar {
    border: none !important;
    padding: 10px !important;
}

.ce-my-message-bubble {
    background-color: #0e73f6 !important;
}

#ce-send-message-button {
    background-color: #0e73f6 !important;
}
