.highlight {
    color: "#5B6871";
    font-weight: 700;
}

.image-embed {
    margin: 20px 0;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin-top: 5px;
    margin-bottom: 20px;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.export-csv {
    font-size: 14px;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
}
.export-csv .export-final {
    margin-top: 5px;
}

/* Extra-Small */
@media (min-width: 600px) {
    .export-csv {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .export-csv .export-final {
        margin-top: 0px;
    }
}

/* Small */
@media (min-width: 900px) {
}

/* Medium */
@media (min-width: 1200px) {
}

/* Large */
@media (min-width: 1536px) {
}
